import Layout from './Layout';

function App() {
  return (
    <div>
      <Layout/>
    </div>
  );
}

export default App;